<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_bitacora_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Bitácora
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
              @click="limpiarFiltros()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <!-- Fecha Inicial -->
                    <div class="form-group row">
                      <label
                        for="fecha_creacion"
                        class="col-md-5"
                        style="font-size: 18px"
                        >Fecha Creación</label
                      >

                      <input
                        type="date"
                        v-model="filtros.fecha_creacion"
                        class="form-control form-control-sm col-md-7"
                        @input="validaFechasForm()"
                        :class="
                          $v.filtros.fecha_creacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <!-- Fecha Final -->
                    <div class="form-group row">
                      <label
                        for="fecha_cierre"
                        class="col-md-5"
                        style="font-size: 18px"
                        >Fecha Cierre</label
                      >

                      <input
                        type="date"
                        v-model="filtros.fecha_cierre"
                        class="form-control form-control-sm col-md-7"
                        @input="validaFechasForm()"
                        :class="
                          $v.filtros.fecha_cierre.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <!-- Sitio -->
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 18px"
                        >Sitio</label
                      >
                      <v-select
                        class="form-control form-control-sm col-md-7 p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="sitios"
                        placeholder="Sitio"
                        label="nombre"
                        @input="selectSitios()"
                        multiple
                        :options="$parent.listasForms.sitios"
                      >
                      </v-select>
                    </div>
                    <!-- Línea Negocio -->
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 18px"
                        >Línea Negocio</label
                      >
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="linea_negocios"
                        placeholder="Línea Negocio"
                        label="nombre"
                        :options="$parent.listasForms.linea_negocio"
                        @input="selectLineas()"
                        multiple
                        class="form-control form-control-sm col-md-7 p-0"
                      ></v-select>
                    </div>
                    <!-- Tipo Novedad -->
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 18px"
                        >Tipo Novedad</label
                      >
                      <select
                        class="form-control form-control-sm col-md-7"
                        v-model="filtros.tipo_novedad"
                      >
                        <option value="">Todos...</option>
                        <option
                          v-for="tipo_novedad in $parent.listasForms
                            .tipo_novedad"
                          :key="tipo_novedad.numeracion"
                          :value="tipo_novedad.numeracion"
                        >
                          {{ tipo_novedad.descripcion }}
                        </option>
                      </select>
                    </div>
                    <!-- Estado -->
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 18px"
                        >Estado</label
                      >
                      <select
                        class="form-control form-control-sm col-md-7"
                        v-model="filtros.estado"
                      >
                        <option value="">Todos...</option>
                        <option
                          v-for="estado in $parent.listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_creacion"
                              v-model="form.fecha_creacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_creacion"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_cierre"
                              v-model="form.fecha_cierre"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_cierre"
                              >Fecha Cierre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitio"
                              v-model="form.sitio"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitio"
                              >Sitio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="linea_negocio"
                              v-model="form.linea_negocio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="linea_negocio"
                              >Línea Negocio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_novedad"
                              v-model="form.tipo_novedad"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_novedad"
                              >Tipo Novedad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="descripcion"
                              v-model="form.descripcion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="descripcion"
                              >Descripción</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="asunto"
                              v-model="form.asunto"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="asunto"
                              >Asunto</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-gradient-navy"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can('tif.solicitudesVehiculos.export') &&
                    !$v.filtros.$invalid
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
              <button
                type="button"
                class="btn btn-sm bg-gradient-danger ml-2"
                @click="limpiarFiltros()"
              >
                Limpiar
                <i class="fas fa-broom"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "BitacoraNovedadadesExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      listasForms: {},
      sitios: [],
      linea_negocios: [],
      filtros: {
        fecha_creacion: null,
        fecha_cierre: null,
        tipo_novedad: null,
        bloque: [],
        sitios_id: [],
        linea_negocios_id: [],
        estado: null,
      },
      form: {
        fecha_creacion: true,
        fecha_cierre: true,
        sitio: true,
        linea_negocio: true,
        tipo_novedad: true,
        descripcion: true,
        asunto: true,
        estado: true,
      },
    };
  },

  validations: {
    filtros: {
      fecha_creacion: {
        required,
      },
      fecha_cierre: {
        required,
      },
    },
  },

  methods: {
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/bitacoraNovedades/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    selectSitios() {
      this.filtros.sitios_id = this.sitios.map((s) => s.id);
    },

    selectLineas() {
      this.filtros.linea_negocios_id = this.linea_negocios.map((l) => l.id);
    },

    validaFechasForm() {
      if (this.filtros.fecha_creacion && this.filtros.fecha_cierre) {
        const fecha_menor = new Date(this.filtros.fecha_creacion);
        const fecha_mayor = new Date(this.filtros.fecha_cierre);

        // Se valida que la fecha creación sea menor que la fecha de la creación
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_cierre = null;
          this.$swal({
            icon: "error",
            title: `La fecha creación no puede ser mayor a la fecha del cierre.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    limpiarFiltros() {
      this.cargando = true;
      this.sitios = [];
      this.linea_negocios = [];
      this.filtros = {
        fecha_creacion: null,
        fecha_cierre: null,
        tipo_novedad: null,
        bloque: [],
        sitios_id: [],
        linea_negocios_id: [],
        estado: null,
      };
      this.form = {
        fecha_creacion: true,
        fecha_cierre: true,
        sitio: true,
        linea_negocio: true,
        tipo_novedad: true,
        descripcion: true,
        asunto: true,
        estado: true,
      };
      this.cargando = false;
    },
  },

  mounted() {},
};
</script>
